<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>报修</h1>
      </el-header>
      <el-main class="scrollable-main">
          <el-form label-width="100px" ref="repairForm" :model="form" :rules="rules" size="large" style="width: 90%">
            <el-form-item label="报修类别" prop="type" class="custom-form-item">
              <el-select clearable v-model="form.type" placeholder="请选择类别" style="width: 100%">
                <el-option label="车间报修" value="车间报修"></el-option>
                <el-option label="维修自查" value="维修自查"></el-option>
                <el-option label="管理巡审" value="管理巡审"></el-option>
                <el-option label="获批技改" value="获批技改"></el-option>
<!--                <el-option label="定期维修" value="定期维修"></el-option>-->
              </el-select>
            </el-form-item>
            <el-form-item label="故障类型" v-if="form.type && form.type !== '获批技改' " class="custom-form-item">
              <el-select clearable v-model="form.failureType" placeholder="请选择故障类型" style="width: 100%">
                <el-option v-for="option in faultTypeOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报修人" class="custom-form-item">
              <span style="font-size: 18px">{{getUserNickname(user.id)}}</span>
            </el-form-item>
            <el-checkbox v-if="flagLineId" v-model="changeTo" style="margin-left: 100px">切换到其他部门</el-checkbox>
            <el-form-item label="部门" prop="partId" class="custom-form-item">
              <span v-if="flagLineId && !changeTo" style="font-size: 18px">{{getDepartmentName(user.id)}}</span>
              <el-select v-else clearable v-model="form.partId" placeholder="请选择部门" style="width: 100%">
                <el-option v-for="item in departments" :key="item.name" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车间" prop="lineId" class="custom-form-item">
              <span v-if="flagLineId && !changeTo" style="font-size: 18px">{{getLineName(user.id)}}</span>
              <el-select v-else clearable v-model="form.lineId" placeholder="请选择车间" style="width: 100%">
                <el-option v-for="item in filteredLines" :key="item.name" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="种类" prop="equipmentType" class="custom-form-item">
              <el-select filterable clearable v-model="form.equipmentType" placeholder="请选择种类" style="width: 100%" @change="filterEquipments">
                <el-option v-for="type in equipmentTypes" :key="type" :label="type" :value="type"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备" prop="equipmentId" class="custom-form-item">
              <el-select filterable clearable v-model="form.equipmentId" placeholder="请选择设备" style="width: 100%">
                <el-option v-for="item in filteredEquipments" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="故障时间" class="custom-form-item">
              <el-date-picker v-model="form.failureTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="故障描述" class="custom-form-item">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="form.description">
              </el-input>
            </el-form-item>
            <el-form-item label="故障图">
<!--              <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"-->
<!--                       :on-success="handleAvatarSuccess" style="display: inline-block" :multiple="true">-->
<!--              <el-button type="flex" justify="center">上传照片</el-button><span style="font-size: 12px">（可传多张）</span>-->
<!--              </el-upload>-->
              <el-upload
                  class="avatar-uploader"
                  action="http://39.106.92.106:9090/files/upload"
                  :show-file-list="true"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeUpload"
                  :multiple="true"
              >
                <el-button type="primary">上传照片</el-button>
                <span style="font-size: 12px">（可传多张）</span>
              </el-upload>
            </el-form-item>
          </el-form>
        <el-row type="flex" justify="center">
          <el-button @click="goToHome" size="large">取消</el-button>
          <el-button type="primary" @click="submitForm" size="large" style="margin-left: 20px">提交</el-button>
        </el-row>
      </el-main>
      <!-- 底部导航栏 -->
<!--      <el-footer class="fixed-footer">-->
<!--        <el-row type="flex" justify="center">-->
<!--          <el-col :span="8" class="footer-item">-->
<!--            <router-link to="/mobile">首页</router-link>-->
<!--          </el-col>-->
<!--          <el-col :span="8" class="footer-item">-->
<!--            <router-link to="/mequipment">设备知识库</router-link>-->
<!--          </el-col>-->
<!--          <el-col :span="8" class="footer-item">-->
<!--            <router-link to="/mperson">个人信息</router-link>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-footer>-->
    </el-container>
  </div>
</template>

<script>
export default {
  name: "MReport",
  data() {
    return {
      form: {
        avatarUrl: null,
        avatarUrl1: null,
      },
      users: [],
      departments: [],
      lines: [],
      equipments: [],
      fileUrls: [],
      flagPartId: null,
      flagLineId: null,
      changeTo: false,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      failureType: {
        '车间报修': [
          { label: '故障', value: '故障' },
          { label: '宕机', value: '宕机' },
        ],
        '维修自查': [
          { label: '预防性', value: '预防性' },
          { label: '故障', value: '故障' },
          { label: '修旧利废', value: '修旧利废' },
        ],
        '管理巡审': [
          { label: '预防性', value: '预防性' },
          { label: '故障', value: '故障' },
        ],
        // '定期维修': [
        //   { label: '预防性', value: '预防性' },
        // ],
        '获批技改': []
      },
      equipmentTypes: [],
      rules: {
        type: [{ required: true, message: '请选择报修类别', trigger: 'change' }],
        equipmentType: [{ required: true, message: '请选择种类', trigger: 'change' }],
        equipmentId: [{ required: true, message: '请选择设备', trigger: 'change' }],
        failureTime: [{ required: true, message: '请选择故障时间', trigger: 'change' }],
      },
    }
  },
  computed: {
    faultTypeOptions() {
      return this.failureType[this.form.type] || [];
    },
    filteredLines() {
      // 根据选择的部门过滤车间
      return this.lines.filter(line => line.partId === this.form.partId);
    },
    // filteredEquipments() {
    //   // 根据选择的车间过滤设备
    //   return this.equipments.filter(equipment => equipment.lineId === (this.users.find(v => v.id === this.user.id).lineId));
    // }
    filteredEquipments() {
      // 根据选择的车间过滤设备
      let filteredByLine = null
      if(this.flagLineId != null && !this.changeTo){
        filteredByLine = this.equipments.filter(equipment => equipment.lineId === (this.users.find(v => v.id === this.user.id).lineId));
      }else{
        filteredByLine = this.equipments.filter(equipment => equipment.lineId === this.form.lineId);
      }
      this.extractUniqueEquipmentTypes(filteredByLine);
      // 根据选择的种类进一步过滤设备
      if (this.form.equipmentType) {
        filteredByLine = filteredByLine.filter(equipment => equipment.type === this.form.equipmentType);
      }
      return filteredByLine;
    }
  },
  created() {
    this.load()
    this.form.failureTime = this.getNow();
  },
  methods: {
    // 获取部门、线体和设备数据
    load() {
      this.request.get("/partment").then(res => {
        this.departments = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
      this.request.get("/equipment").then(res => {
        this.equipments = res.data;
      });
      this.request.get("/user").then(res => {
        this.users = res.data;
        this.flagPartId = this.users.find(v => v.id === this.user.id).partId
        this.flagLineId = this.users.find(v => v.id === this.user.id).lineId
        console.log("flagPartId:"+this.flagPartId+",flagLineId:"+this.flagLineId)
      });

    },

    getNow() {
      // 获取当前时间并格式化为 "yyyy-MM-dd HH:mm:ss"
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 补齐两位
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    extractUniqueEquipmentTypes(filteredByLine) {
      const types = new Set();
      filteredByLine.forEach(equipment => {
        types.add(equipment.type);
      });
      this.equipmentTypes = Array.from(types);
    },
    // 返回首页
    goToHome() {
      this.$router.push('/mobile');
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const width = img.width;
            const height = img.height;

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            // 开始以 0.9 的质量压缩图片，然后递归调整
            this.compressImage(canvas, file.type, 0.5, resolve, reject);
          };
        };

        reader.onerror = (error) => {
          console.error("图片读取失败：", error);
          reject(error);
        };
      });
    },

    compressImage(canvas, fileType, quality, resolve, reject) {
      canvas.toBlob((blob) => {
        if (blob.size <= 50 * 1024) {
          const compressedFile = new File([blob], `compressed_${Date.now()}.jpg`, {
            type: fileType,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        } else if (quality > 0.1) {
          this.compressImage(canvas, fileType, quality - 0.1, resolve, reject);
        } else {
          const compressedFile = new File([blob], `compressed_${Date.now()}.jpg`, {
            type: fileType,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        }
      }, fileType, quality);
    },

    handleAvatarSuccess(res) {
      if (!Array.isArray(this.fileUrls)) {
        this.fileUrls = [];
      }
      this.fileUrls.push(res.data);
    },

    filterEquipments() {
      // 触发重新计算 filteredEquipments
      this.$forceUpdate();
    },
    submitForm() {
      console.log(this.fileUrls)
      this.$refs.repairForm.validate((valid) => {
        if (valid) {
          if(this.fileUrls === null || this.fileUrls.length === 0){
            this.$message.error("请上传图片")
            return;
          }else {
            this.save();
          }
        } else {
          this.$message.error("请填写完整的报修信息");
          return false;
        }
      });
    },
    // 保存报修信息
    save() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      console.log(formattedRecordTime); // 输出格式为 "YYYY-MM-DD HH:MM:SS"
      let partId = this.users.find(v => v.id === this.user.id).partId;
      let lineId = this.users.find(v => v.id === this.user.id).lineId;
      let equipmentName = this.equipments.find(v => v.id === this.form.equipmentId).name;
      if(partId == null){
        partId = this.form.partId
      }
      if(lineId == null){
        lineId = this.form.lineId
      }
      const formData = {
        userId: this.user.id, // 报修人的 id
        type: this.form.type,
        partId: partId,
        lineId: lineId,
        equipmentId: this.form.equipmentId,
        equipmentName: equipmentName,
        failureType: this.form.failureType,
        failureTime: this.form.failureTime,
        description: this.form.description,
        recordTime: formattedRecordTime,
      };
      let requestData = {}
      if(this.fileUrls != null) {
        requestData = {
          fileUrls: this.fileUrls,
          formattedRecordTime: formattedRecordTime
        }
      }else{
        this.$message.warning("图片为空")
      }
      this.request.post("/repairReport", formData).then(res => {
        if (res.code === '200') {
          this.request.post("/reportFile/saveFile", requestData).then(res => {
            this.$message.success("提交成功")
            this.request.post("/wechat/sendReport", formData)
          })
          this.$router.push('/mobile');
        } else {
          this.$message.error("提交失败")
        }
      })
      // const formData1 = {
      //   avatarUrl: this.fileList.map(file => file.url)
      // };
      // this.request.post("/repairReport", formData1)
    },
    getUserNickname(userId) {
      const user = this.users.find(v => v.id === userId);
      return user ? user.nickname : '';
    },
    getDepartmentName(userId) {
      const user = this.users.find(v => v.id === userId);
      if (user && this.departments.length) {
        const department = this.departments.find(v => v.id === user.partId);
        return department ? department.name : '';
      }
      return '';
    },
    getLineName(userId) {
      const user = this.users.find(v => v.id === userId);
      if (user && this.lines.length) {
        const line = this.lines.find(v => v.id === user.lineId);
        return line ? line.name : '';
      }
      return '';
    },
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
/*  text-align: center;!**!*/
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  display: flex;
  align-items: center;
  justify-content: center; /* 居中对齐 */
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
